'use strict'

import * as coreCommon from 'assets/core/js/common'
import consentMode from 'assets/core/js/module/consentMode'
import { DataLayer } from 'assets/core/js/module/dataLayer'
import design from './design'
import header from './header'
import Modal from 'assets/core/js/module/modal'
import selects from 'assets/core/js/page/common/selects'
// import webvitals from 'assets/core/js/webvitals'

export default {
  preInit: function () {
    coreCommon.polyfills()
    if (window.pageDataLayer && window.coreGtmId && window.pageDataLayer[window.coreGtmId]) {
      coreCommon.initAxeptio(window.pageDataLayer[window.coreGtmId] as DataLayer[])
    }
    // webvitals()
    selects()
  },

  init: function () {
    coreCommon.initLinks()
    coreCommon.initLazyLoading()
    header.initMenus()
    design.init()
    consentMode.init()

    document.addEventListener('submit', function (e) {
      const target = e.target as HTMLElement

      if (target.classList.contains('prevent-double-submission')) {
        const alreadySubmitted = target.getAttribute('data-already-submitted')

        if (!alreadySubmitted) {
          target.setAttribute('data-already-submitted', '1')
          target.querySelectorAll('button[type=submit]').forEach(function (el) {
            el.classList.add('dca-button--disabled')
          })

          return true
        }

        return false
      }
    })

    window.onpopstate = function (e: PopStateEvent) {
      const target = e.target as Window

      const state = e.state as {
        forceRefresh: boolean
      }

      if (state && state.forceRefresh) {
        window.location.href = target.location.href
      }
    }

    document.querySelectorAll<HTMLElement>('.properties__labels-number').forEach(function (el) {
      el.addEventListener('click', function (e) {
        const target = e.target as HTMLElement

        e.stopPropagation()
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        target.parentNode.parentNode.classList.add('labels--all')
      })
    })

    this.initNewsletterRegistrationForm()
  },

  initNewsletterRegistrationForm: function () {
    const wrapper = document.querySelector('.newsletter__form-data')
    if (!wrapper) {
      return false
    }

    const inputEmail = document.getElementById('newsletterInputEmail') as HTMLInputElement
    const modal = new Modal(document.getElementById('modal-newsletter') as HTMLElement)

    document.querySelector('.footer__newsletter input[type="submit"]')?.addEventListener('click', (e) => {
      e.preventDefault()
      const iframe = document.getElementById('newsletter-iframe') as HTMLIFrameElement

      if (!iframe?.hasAttribute('src')) {
        const params = inputEmail.value ? `?email=${encodeURI(inputEmail.value)}` : ''
        iframe.src = iframe.getAttribute('data-src') + params
      }

      modal.open()
    })
  },

  isMobile: function () {
    const mediaQuery = coreCommon.queryMedia('mobile')

    if (mediaQuery) {
      return mediaQuery.matches
    }

    return false
  },

  isTablet: function () {
    const mediaQuery = coreCommon.queryMedia('tablet')

    if (mediaQuery) {
      return mediaQuery.matches
    }

    return false
  },

  getMediaQuerySize: function (size: string) {
    return coreCommon.getMediaQuerySize(size)
  },

  queryMedia: function (size: string) {
    return coreCommon.queryMedia(size)
  },
}
