'use strict'

import { DataLayer } from 'assets/core/js/module/dataLayer'
import { polyfill } from 'seamless-scroll-polyfill'

const mediaQueries = MEDIA_QUERIES
const intlCache: Record<string, Intl.NumberFormat> = {}

export function polyfills(): boolean {
  // enable scrollIntoView polyfill for all browsers
  polyfill()

  // requestSubmit is not supported by enough browsers so we keep it outside of the polyfills script
  if (!HTMLFormElement.prototype.requestSubmit) {
    HTMLFormElement.prototype.requestSubmit = function () {
      const submitBtn = document.createElement('input')
      submitBtn.type = 'submit'
      submitBtn.hidden = true
      this.appendChild(submitBtn)
      submitBtn.click()
      this.removeChild(submitBtn)
    }
  }

  return true
}

export function loadLink(url: string, id: string): Promise<unknown> {
  return new Promise((resolve, reject) => {
    const link = document.createElement('link')
    link.rel = 'stylesheet'
    if (id) {
      link.id = id
    }
    link.href = url
    link.addEventListener('error', reject)
    link.addEventListener('load', resolve)
    document.body.appendChild(link)
  })
}

export function loadScript(url: string, id: null | string, onLoad: () => void | Promise<void>): Promise<void> {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = url
    if (id) {
      script.id = id
    }
    script.addEventListener('error', reject)
    script.addEventListener('load', () => resolve(onLoad()))
    document.body.appendChild(script)
  })
}

/**
 * Custom listener axeptio listener to send events to our dataLayer
 * It registers a callback inside _axcb which is only used if the axeptio tag is present
 */
export function initAxeptio(dataLayer: Array<Record<string, unknown>> | DataLayer[]): void {
  if (!Array.isArray(window._axcb)) {
    window._axcb = []
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
  window._axcb.push(function (axeptio: Record<string, any>): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    axeptio.on('cookies:complete', function (choices: Record<string, boolean>) {
      Object.keys(choices).forEach(function (serviceSlug) {
        if (choices[serviceSlug]) {
          const event: Record<string, unknown> = {
            event: 'axeptio_activate_' + serviceSlug,
          }
          event[serviceSlug] = true
          dataLayer.push(event)
        }
      })
    })
  })
}

export function initLinks(): void {
  const isExternalLink = (target: HTMLElement): boolean => {
    return (
      target.matches('.external-link') ||
      (!isMobile() && target.matches('[data-desktop-target]') && target.getAttribute('data-desktop-target') === '_blank')
    )
  }

  document.addEventListener('click', function (e: MouseEvent) {
    const target = e.target as HTMLElement
    const rowLinkEl = target.closest('.row-link')
    if (rowLinkEl && !target.classList.contains('no-link') && rowLinkEl.querySelector('.row-target')) {
      window.location.href = (rowLinkEl.querySelector('.row-target') as HTMLLinkElement).href
    }

    if (target.matches('[data-href]') && !isExternalLink(target) && target.hasAttribute('data-href')) {
      window.location.href = target.getAttribute('data-href') as string
    }

    if (isExternalLink(target)) {
      e.preventDefault()
      e.stopPropagation()

      try {
        const windowOpen = window.open()
        if (windowOpen) {
          windowOpen.opener = null
          windowOpen.location = target.getAttribute('data-href') || (target as HTMLLinkElement).href
        }
      } catch (exception) {
        window.location.href = target.getAttribute('data-href') || (target as HTMLLinkElement).href
      }
    }

    const rowExternalLinkEl = target.closest('.row-external-link')
    if (rowExternalLinkEl && !target.classList.contains('no-link')) {
      e.preventDefault()
      e.stopPropagation()

      const windowOpen = window.open()
      if (windowOpen) {
        windowOpen.opener = null
        windowOpen.location = (rowExternalLinkEl.querySelector('.row-target') as HTMLLinkElement).href
      }
    }
  })
}

export function initLazyLoading(elements?: NodeListOf<Element> | string): void {
  let lazyImages = elements || '.lazy'

  if (typeof lazyImages === 'string') {
    lazyImages = document.querySelectorAll<HTMLElement>(lazyImages)
  }

  if ('IntersectionObserver' in window) {
    const lazyImageObserver = new window.IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          lazyLoadImage(entry.target as HTMLImageElement)
          lazyImageObserver.unobserve(entry.target)
        }
      })
    })

    lazyImages.forEach(function (lazyImage) {
      if (!lazyImage.hasAttribute('data-lazy-watched')) {
        lazyImage.setAttribute('data-lazy-watched', 'true')
        lazyImageObserver.observe(lazyImage)
      }
    })
  }
}

export function lazyLoadImage(image: HTMLImageElement): HTMLImageElement {
  if (image.hasAttribute('data-src')) {
    image.src = image.getAttribute('data-src') as string
    image.removeAttribute('data-src')
  }

  if (image.hasAttribute('data-srcset')) {
    image.srcset = image.getAttribute('data-srcset') as string
    image.removeAttribute('data-srcset')
  }

  image.classList.remove('lazy')
  image.classList.add('lazy-loaded')

  image.querySelectorAll<HTMLImageElement>('[data-src]').forEach((el) => {
    el.src = el.getAttribute('data-src') as string
    el.removeAttribute('data-src')
  })

  image.querySelectorAll<HTMLImageElement>('[data-srcset]').forEach((el) => {
    el.srcset = el.getAttribute('data-srcset') as string
    el.removeAttribute('data-srcset')
  })

  return image
}

export function getMediaQuerySize(size: string): string | null {
  return mediaQueries[size] || null
}

export function queryMedia(size: string): MediaQueryList | false {
  if (mediaQueries[size] && typeof window.matchMedia !== 'undefined') {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return window.matchMedia(`(max-width: ${mediaQueries[size]})`)
  }

  return false
}

export function formatCurrency(number: number, currency: string | undefined, hideDecimals = false): string | number {
  currency = currency || document.body.dataset.currency || 'EUR'

  if (window.Intl) {
    const config: Intl.NumberFormatOptions = {
      currency,
      minimumFractionDigits: 0,
      style: 'currency',
    }

    if (number % 1 !== 0) {
      config.minimumFractionDigits = 2
    }

    if (hideDecimals) {
      config.maximumFractionDigits = 0
      config.minimumFractionDigits = 0
    }

    const locale = (document.querySelector('html') as HTMLElement).lang
    const cacheKey = `${currency}_${config.minimumFractionDigits || 0}_${hideDecimals.toString()}_${locale}`

    if (!intlCache[cacheKey]) {
      intlCache[cacheKey] = new window.Intl.NumberFormat(locale, config)
    }

    // @ts-ignore
    return intlCache[cacheKey].format(number)
  }

  return number
}

export function formatDate(value: string): string {
  const date = new Date(value)
  const locale = (document.querySelector('html') as HTMLElement).lang

  return date.toLocaleDateString(locale)
}

export function createXhr(verb: string, url: string): XMLHttpRequest {
  const xhr = new XMLHttpRequest()
  xhr.open(verb, url)
  xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')

  if (verb === 'POST') {
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
  }

  return xhr
}

export function showFlashMessage(message: string, type = 'error', timeout = 10 * 1000): void {
  const buttonEl = document.createElement('button')
  buttonEl.className = 'close'
  buttonEl.setAttribute('type', 'button')
  buttonEl.setAttribute('data-target', 'alert-flash')

  const messageEl = document.createElement('p')
  messageEl.innerHTML = message

  const alertEl = document.createElement('div')
  alertEl.className = 'alert alert-' + type
  alertEl.appendChild(buttonEl)
  alertEl.appendChild(messageEl)

  const alertContainerEl = document.createElement('div')
  alertContainerEl.setAttribute('id', 'alert-flash')
  alertContainerEl.appendChild(alertEl)

  document.body.insertBefore(alertContainerEl, document.body.firstChild)

  const alertFlashEl = document.getElementById('alert-flash')

  document.addEventListener('click', function (e) {
    if ((e.target as HTMLElement).matches('#alert-flash .close') && alertFlashEl && alertFlashEl.parentNode) {
      alertFlashEl.parentNode.removeChild(alertFlashEl)
    }
  })

  setTimeout(function () {
    if (alertFlashEl && alertFlashEl.parentNode) {
      alertFlashEl.parentNode.removeChild(alertFlashEl)
    }
  }, timeout)
}

export function isMobile(): boolean {
  const size = getMediaQuerySize('mobile')

  if (!size) {
    return false
  }

  return window.matchMedia(`(max-width: ${size})`).matches
}

export function isTablet(): boolean {
  const size = getMediaQuerySize('tablet')

  if (!size) {
    return false
  }

  return window.matchMedia(`(max-width: ${size})`).matches
}

export function isDesktop(): boolean {
  const size = getMediaQuerySize('desktop')

  if (!size) {
    return false
  }

  return window.matchMedia(`(min-width: ${size})`).matches
}

export function isAndroid(): boolean {
  return navigator.userAgent.match(/Android/i) !== null
}

export function isIOS(): boolean {
  return navigator.userAgent.match(/iPhone|iPad|iPod/i) !== null
}

export function createNodeFromHTML(html: string): HTMLElement {
  const div = document.createElement('div')
  div.innerHTML = html
  return div.firstElementChild as HTMLElement
}

export function debounce(delay: number, fn: () => void): () => void {
  let timerId: null | ReturnType<typeof setTimeout>
  return function (...args) {
    if (timerId) {
      clearTimeout(timerId)
    }
    timerId = setTimeout(() => {
      fn(...args)
      timerId = null
    }, delay)
  }
}
